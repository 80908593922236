import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import { Layout } from "../components/common";
import { MetaData } from "../components/common/meta";
import ContactForm from "../components/ContactForm";
import Customers from "../components/Customers";
import OurValue from "../components/OurValue";
import Footer from "../components/Footer";

/**
* Single post view (/:slug)
*
* This file renders a single post and loads all the content.
*
*/
const Post = ({ data, location }) => {
    const post = data.ghostPost

    return (
        <>
            <MetaData
                data={data}
                location={location}
                type="article"
            />
            <Helmet>
                <style type="text/css">{`${post.codeinjection_styles}`}</style>
            </Helmet>
            <Layout>
                <div className="container">
                    <div className="blogpagelayout">
                        <article className="content">
                            {post.feature_image ? (
                                <figure className="post-feature-image">
                                    <img
                                        src={post.feature_image}
                                        alt={post.title}
                                    />
                                </figure>
                            ) : null}
                            <section className="post-full-content">
                                <h1 className="content-title">{post.title}</h1>

                                <section
                                    className="content-body load-external-scripts"
                                    dangerouslySetInnerHTML={{
                                        __html: post.html,
                                    }}
                                />
                            </section>
                        </article>
                    </div>
                </div>
            </Layout>
            <OurValue />
            <Customers />
            <ContactForm />
            <Footer />
        </>
    )
}

Post.propTypes = {
    data: PropTypes.shape({
        ghostPost: PropTypes.shape({
            codeinjection_styles: PropTypes.object,
            title: PropTypes.string.isRequired,
            html: PropTypes.string.isRequired,
            feature_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
}

export default Post

export const postQuery = graphql`
    query($slug: String!) {
        ghostPost(slug: { eq: $slug }) {
            ...GhostPostFields
        }
    }
`
